import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import "./Navbar.css";
import logo from "../images/Logo.png"

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav>
      <NavLink to="./">
        <img src={logo} alt="Logo" />
      </NavLink>
      <ul className="nav-list">
        <li>
          <NavLink to="/Home" activeClassName="active">Home</NavLink>
        </li>
        <li>
          <NavLink to="/About" activeClassName="active">About</NavLink>
        </li>
        <li>
          <NavLink to="/Services" activeClassName="active">Services</NavLink>
        </li>
        {/* <li>
          <NavLink to="/Resources" activeClassName="active">Resources</NavLink>
        </li> */}
      </ul>
      <div className={`menu-icon ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`menu ${isOpen ? "open" : ""}`}>
        <li>
          <NavLink to="/Home" activeClassName="active">Home</NavLink>
        </li>
        <li>
          <NavLink to="/About" activeClassName="active">About</NavLink>
        </li>
        <li>
          <NavLink to="/Services" activeClassName="active">Services</NavLink>
        </li>
        {/* <li>
          <NavLink to="/Resources" activeClassName="active">Resources</NavLink>
        </li> */}
      </ul>
      <NavLink className="ctn-btn" to="/Contact">
        Contact Us
      </NavLink>
    </nav>
  );
}

export default Navbar;
