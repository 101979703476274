import { Routes, Route } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './components/Home'; // Import your home component
import About from './components/About'; // Import your about component
import Services from './components/Services'; // Import your services component
// import Resources from './components/Resources'; // Import your resources component
import Contact from './components/Contact';
import ScrollToTop from './components/ScrollToTop'; // Import your resources component

function App() {
  return (
    <>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path="/Home" element={<Home />} /> 
        <Route path="/About" element={<About />} />
        <Route path="/Services" element={<Services />} />
        {/* <Route path="/Resources" element={<Resources />} /> */}
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
